import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from "react-ga4";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize("G-8TZGSHLBPM");
root.render(<App />);
serviceWorkerRegistration.register();
